import { Routes, Route } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import ChangePassword from './ChangePassword';
import TwoFactorAuthentication from './TwoFactorAuthentication';
// import { useState, ReactNode } from 'react';
// import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
// import { SiteDetail } from 'api/site';
// import { useTranslation } from 'react-i18next';
// import { ChangeLabelTooltip } from 'component/partial/ChangeLabelTooltip';
// import { cssVar } from 'utils/css';
// import { useParams } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCreditCard, faGear, faLock } from '@fortawesome/pro-regular-svg-icons';

// type TabType = 'account_and_security' | 'billing' | 'user_and_site_management';

// function CardMenu({
//   iconNode,
//   tab,
//   currentTab,
//   onMenuChange,
//   label,
// }: {
//   readonly iconNode: ReactNode;
//   readonly tab: TabType;
//   readonly currentTab: TabType | undefined;
//   readonly onMenuChange: (tabType: TabType) => void;
//   readonly label: string;
// }) {
//   const activeStyles =
//     currentTab === tab
//       ? {
//           backgroundColor: cssVar('--color-white'),
//           color: cssVar('--primary'),
//         }
//       : {};

//   return (
//     <Card
//       sx={{
//         backgroundColor: 'transparent',
//         cursor: 'pointer',
//         height: '6.5625rem',
//         textAlign: 'center',
//         minWidth: '6.875rem',
//         ...activeStyles,
//         '&:hover': {
//           backgroundColor: cssVar('--color-white'),
//           color: cssVar('--primary'),
//         },
//         '&:not(:first-of-type)': {
//           marginTop: '0',
//         },
//         '&:not(:last-child)': {
//           marginBottom: '0',
//         },
//         '& i': {
//           alignItems: 'center',
//           display: 'flex',
//           height: '2.5rem',
//           margin: '0 auto 0.625rem auto',
//           width: '1.5rem',
//           '& svg': {
//             display: 'block',
//             maxHeight: '100%',
//             maxWidth: '100%',
//           },
//         },
//       }}
//       onClick={() => onMenuChange(tab)}
//     >
//       <CardContent
//         sx={{
//           paddingLeft: '0',
//           paddingRight: '0',
//         }}
//       >
//         <i>{iconNode}</i>
//         <Typography
//           sx={{
//             fontWeight: '600',
//             fontSize: '12px',
//           }}
//         >
//           {label}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// }

export function Profile() {
  // const { t } = useTranslation();
  // const { tabName = 'account_and_security' } = useParams<{ tabName?: TabType }>();
  // currently we are just passing thru - new designs have this navigation
  // return (
  //   <main id="mainContent">
  //     <Container maxWidth="lg">
  //       <Box marginBottom={3}>
  //         <Stack spacing={1.5}>
  //           <Typography variant="h1" data-testid="domainLabel">
  //             {t('settings')}
  //           </Typography>
  //           <Grid
  //             container
  //             columnSpacing={2}
  //             sx={{
  //               marginLeft: '-16px !important',
  //             }}
  //           >
  //             <Grid item sm={12} md={4}>
  //               <CardMenu
  //                 iconNode={<FontAwesomeIcon size="2x" icon={faLock} />}
  //                 onMenuChange={() => {}}
  //                 label={t('account_and_security')}
  //                 currentTab={tabName}
  //                 tab="account_and_security"
  //               />
  //             </Grid>
  //             <Grid item sm={12} md={4}>
  //               <CardMenu
  //                 iconNode={<FontAwesomeIcon size="2x" icon={faCreditCard} />}
  //                 onMenuChange={() => {}}
  //                 label={t('billing')}
  //                 currentTab={tabName}
  //                 tab="billing"
  //               />
  //             </Grid>
  //             <Grid item sm={12} md={4}>
  //               <CardMenu
  //                 iconNode={<FontAwesomeIcon size="2x" icon={faGear} />}
  //                 onMenuChange={() => {}}
  //                 label={t('user_and_site_management')}
  //                 currentTab={tabName}
  //                 tab="user_and_site_management"
  //               />
  //             </Grid>
  //           </Grid>
  //         </Stack>
  //       </Box>
  //     </Container>
  //   </main>
  // );

  return (
    <Routes>
      <Route path={linkHelper.profile.changePassword.path} element={<ChangePassword />} />
      <Route
        path={linkHelper.profile.twoFactorAuthentication.path}
        element={<TwoFactorAuthentication />}
      />
    </Routes>
  );
}
